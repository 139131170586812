// store/index.js

import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: false,
    sysWeeks: [],
    sysYears: [],
	recordQuantity:20,
  },
  mutations: {
    SET_WEEKS(state, weekData) {
      state.sysWeeks = weekData;
    },
    SET_YEARS(state, yearData) {
      state.sysYears = yearData;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
  },
  actions: {
    async loadData({ commit }) {
      try {
        commit('SET_LOADING', true);
        const [weeksResponse, yearsResponse] = await Promise.all([
          axios.get('sysData/weeks'),
          axios.get('sysData/Year')
        ]);

        commit('SET_WEEKS', weeksResponse.data);
        commit('SET_YEARS', yearsResponse.data);
		commit('SET_LOADING', false);
      } catch (error) {
        console.error('加载数据失败:', error);
        commit('SET_LOADING', false);
      } finally {
        commit('SET_LOADING', false);
      }
    },
  },
});
