<template>
	<div id="app">
		<el-container>
			<el-header style="height: 100px;">
				<div class="logo">
					<img src="./assets/img/toplogo.jpg" @click="homepage" />
				</div>
				<div class="searchBox" v-if="isLoggedIn">
					<el-row type="flex" justify="start">
						<el-col :span="15">
							<el-input v-model="searchStr" placeholder="输入学生姓名查询信息"></el-input>
						</el-col>
						<el-col :span="4" style="padding-left: 2px;">
							<el-button type="primary" icon="el-icon-search" @click="handleSearch">查找</el-button>
						</el-col>
					</el-row>
				</div>
				<div class="FPLog" v-if="isFirsPage">
					<el-form ref="lform" :model="loginform" :rules="rules" label-width="80px">
						<!-- 第一行 -->
						<el-form-item>
							<div class="form-row">
								<img src="./assets/img/ane_user_1.png" alt="placeholder" class="image" />
								<el-input name="name" v-model="loginform.name" placeholder="请输入用户名"></el-input>
								<el-button type="primary" @click="login">登录</el-button>
							</div>
						</el-form-item>

						<!-- 第二行 -->
						<el-form-item>
							<div class="form-row">
								<img src="./assets/img/ane_pwd_2.png" alt="placeholder" class="image" />
								<el-input name="password" type="password" v-model="loginform.pass" placeholder="请输入密码"
									autocomplete="off"></el-input>
								<el-button type="danger" @click="logout2">登出</el-button>
							</div>
						</el-form-item>
					</el-form>
				</div>
			</el-header>
			<el-main>
				<router-view />
			</el-main>
			<div class="footer">
				<span>CopyRight © 德育云信息服务版权所有 咨询电话:157 0677 0996 </span>
				<a href="https://beian.miit.gov.cn/" target="_blank" style="cursor:pointer">备案号:浙ICP备2023020133号-1</a>
			</div>
		</el-container>
	</div>
</template>

<script>
	import {
		md5
	} from './js/JSMD5.js';
	export default {
		data() {
			return {
				router: true,
				defaultActive: "",
				isLoggedIn: false,
				isFirsPage: true,
				username: sessionStorage.getItem("UserName"),
				searchStr: '',
				loginform: {
					name: "",
					pass: ""
				},
				rules: {
					name: [{
						required: true,
						message: "请输入用户名",
						trigger: "blur"
					}],
					pass: [{
							required: true,
							message: "请输入密码",
							trigger: "blur"
						},
						{
							min: 5,
							max: 20,
							message: "密码长度在 5 到 20 个字符",
							trigger: "blur"
						}
					]
				}
			};
		},
		methods: {
			homepage() {
				this.$router.push({
					name: 'default',
					path: '/admin/default',
				});
			},
			logout() {
				// 清除所有的会话数据
				sessionStorage.clear();
				this.$LoginName.value = '';
				//重定向到登录页面
				this.$router.push('/login');

			},
			logout2() {
				// 清除所有的会话数据
				sessionStorage.clear();
				this.$LoginName.value = '';
				this.$message({
					message: "登录信息凭证已清除",
					type: "success"
				});

			},
			async handleSearch() {
				this.$router.push({
					path: '/admin/studentSearchResult',
					query: {
						studentName: this.searchStr,
					}
				});
			},
			login() {
				this.$refs.lform.validate(valid => {
					if (valid) {
						// 构造请求体数据
						const formData = {
							username: this.loginform.name,
							password: md5.hexMD5(this.loginform.pass),
						};

						// 发起 POST 请求
						this.$http.post('/Login', formData)
							.then(response => {
								window.console.log(response);
								if (response.data.success) {
									this.$message({
										message: `登录成功!欢迎您${this.loginform.name}老师！`,
										type: "success"
									});
									const {
										currentWeek,
										currentDay,
										currentWeekDay
								} = this.getCurrentWeekAndDay(response.data.schoolData.StartDate, response
										.data.schoolData.StartWeek)
									sessionStorage.setItem("token", response.data.schoolData
										.token); // 保存token字段
									sessionStorage.setItem("currentWeek", currentWeek); // 保存当前周次
									sessionStorage.setItem("currentWeekDay", currentWeekDay); // 保存当前日期-星期几
									sessionStorage.setItem("StartDate", response.data.schoolData
										.StartDate); // 保存起始时间
									sessionStorage.setItem("currentDay", currentDay); // 保存当前周次
									sessionStorage.setItem("Term", response.data.schoolData.Term); // 保存当前学期
									sessionStorage.setItem("Year", response.data.schoolData.Year); // 保存当前学年
									sessionStorage.setItem("UserName", this.loginform.name); // 保存用户名
									sessionStorage.setItem("UserId", response.data.schoolData
										.TIid); // 保存学校代码
									sessionStorage.setItem("SchoolCode", response.data.schoolData
										.SchoolCode); // 保存学校代码
									sessionStorage.setItem("UserLimit", response.data.schoolData
										.UserCategorys); // 用户类型
									this.$router.push('/admin/default')
									this.$LoginName.value = this.loginform.name;
								} else {
									this.$message({
										message: "账号或密码错误",
										type: "error"
									});
								}
							})
							.catch(error => {
								window.console.log(error);
								if (error.response) {
									// 后端返回了错误响应
									this.$message({
										message: "请求错误：" + error.response.data.message,
										type: "error"
									});
								} else {
									// 请求发送失败
									this.$message({
										message: "网络或程序异常：" + error.message,
										type: "error"
									});
								}
							});
					} else {
						this.$message({
							message: "请输入合法的值",
							type: "error"
						});
						return false;
					}
				});
			},
			getCurrentWeekAndDay(StartTime, Week) {
				const weekDayList = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
				const startTimestamp = new Date(StartTime).getTime();
				const startTimestampPlus8Hours = startTimestamp + (8 * 60 * 60 * 1000); // 加上8小时
				const currentTimestamp = Date.now(); // 获取当前时间的时间戳
				const timeDiff = currentTimestamp - startTimestampPlus8Hours; // 计算时间间隔（毫秒数）
				const passedWeeks = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 7)); // 计算已过的周数（去除小数）
				const currentWeek = Week + passedWeeks; // 计算当前周次
				let currentDay = new Date().getDay(); // 星期日为0， 星期一为1，...，星期六为6
				let currentWeekDay = weekDayList[currentDay];
				return {
					currentWeek,
					currentDay,
					currentWeekDay
				};
			},
		},
		computed: {


		},
		watch: {
			//点击登录按钮的消失与出现
			$route(to, from) {
				if (to.path.startsWith('/admin')) {
					this.isLoggedIn = true;
				} else {
					this.isLoggedIn = false;
				}
				if (to.path === '/') {
					this.isFirsPage = true;
				} else {
					this.isFirsPage = false;
				}
			},
			'$LoginName.value'(newVal) {
				this.username = newVal

				//用于一个小时后使得用户失去登录凭证，需要重新登录
				// // 清除上一个定时器
				// if (this.loginTimeout) {
				//   clearTimeout(this.loginTimeout);
				// }

				// // 设置新的定时器
				// this.loginTimeout = setTimeout(() => {
				//   this.logout();
				// }, 3600000); // 3600000 毫秒 = 1 小时

			},
		},

	};
</script>

<style lang="scss">
	* {
		padding: 0;
		margin: 0;
	}

	html,
	body {
		height: 100%;
	}

	#app {
		font-family: "Avenir", Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		color: #2c3e50;
	}

	.el-header {
		display: flex;
		justify-content: space-between;
		align-content: center;
		width: 100%;
		margin: 0 auto;

		.logo {
			padding: 10px 0px;

			img {
				width: 100%;
				line-height: 60px;
			}
		}

		.Login {
			margin-top: 18px;
		}
	}

	.el-main {
		padding: 0 !important;
	}

	.footer {
		font-size: 20px;
		font-weight: 200;
		color: #333;
		text-align: center;
		background-color: rgb(1, 125, 163);
		height: 60px;
		line-height: 60px;
		color: white;


		a:visited {
			color: #ffffff;
			text-decoration: none;
		}

		a:hover {
			text-decoration: none;
		}

		a {
			text-decoration: none;
		}
	}

	.searchBox {
		padding-top: 25px;

	}


	.FPLog {
		padding: 0px;
		margin: 0px;

		.form-row {
			display: flex;
			align-items: center;
			margin: 5px 0px;
		}


		.el-input {
			flex: 1;
			margin-right: 10px;
		}

		.el-button {
			margin-left: 10px;
		}

		.el-form-item {
			margin: 0px;
		}

		image {
			width: 40px;
			height: 40px;
		}
	}
</style>