import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

let router = new Router({
	routes: [{
			path: '/',
			name: 'home',
			component: () => import('./views/Product.vue')
		},
		{
			path: '/login',
			name: 'login',
			component: () => import('./views/Login.vue')
		},
		{
			path: '/admin',
			name: 'admin',
			meta: {
				requireAuth: true
			},
			component: () => import('./views/Admin.vue'),
			children: [{
					path: '/admin/user',
					name: 'user',
					component: () => import('./views/Admin/User.vue'),
				},
				{
					path: '/admin/Today_violation',
					name: 'Today_violation',
					component: () => import('./views/Admin/Today_violation.vue'),
				},
				{
					path: '/admin/Student_EducatioNameList',
					name: 'Student_EducatioNameList',
					component: () => import('./views/Admin/Student_EducatioNameList.vue')
				},
				{
					path: '/admin/StudentVacate',
					name: 'StudentVacate',
					component: () => import('./views/Admin/StudentVacate.vue')
				},
				{
					path: '/admin/BedroomViolation',
					name: 'BedroomViolation',
					component: () => import('./views/Admin/BedroomViolation.vue')
				},
				{
					path: '/admin/classwork_infodonenumber',
					name: 'classwork_infodonenumber',
					component: () => import('./views/Admin/classwork_infodonenumber.vue')
				}, {
					path: '/admin/managerDuty',
					name: 'managerDuty',
					component: () => import('./views/Admin/managerDuty.vue')
				},
				{
					path: '/admin/Add_ManagerDuty',
					name: 'Add_ManagerDuty',
					component: () => import('./views/Admin/Add_ManagerDuty.vue')
				},
				{
					path: '/admin/violation',
					name: 'violation',
					component: () => import('./views/Admin/violation.vue')
				},
				{
					path: '/admin/ClassLeader',
					name: 'ClassLeader',
					component: () => import('./views/Admin/ClassLeader.vue')
				},
				{
					path: '/admin/punishment',
					name: 'punishment',
					component: () => import('./views/Admin/punishment.vue')
				},
				{
					path: '/admin/criticism',
					name: 'criticism',
					component: () => import('./views/Admin/criticism.vue')
				},
				{
					path: '/admin/Honor',
					name: 'Honor',
					component: () => import('./views/Admin/Honor.vue')
				},

				{
					path: '/admin/class_WeekDayViolation',
					name: 'class_WeekDayViolation',
					component: () => import('./views/Admin/class_WeekDayViolation.vue')
				},
				{
					path: '/admin/upLoadImgTest',
					name: 'upLoadImgTest',
					component: () => import('./views/Admin/upLoadImgTest.vue')
				},
				{
					path: '/admin/importData',
					name: 'importData',
					component: () => import('./views/Admin/importData.vue'),
					children: [{
						path: '/admin/importData/TeacherInfo',
						name: 'TeacherInfo',
						component: () => import('./views/Admin/ImportPage/TeacherInfo.vue')
					}, {
						path: '/admin/importData/StudentInfo',
						name: 'StudentInfo',
						component: () => import('./views/Admin/ImportPage/StudentInfo.vue')
					}, {
						path: '/admin/importData/BedRoomInfo',
						name: 'BedRoomInfo',
						component: () => import('./views/Admin/ImportPage/BedRoomInfo.vue')
					}, {
						path: '/admin/importData/ExamScore',
						name: 'ExamScore',
						component: () => import('./views/Admin/ImportPage/ExamScore.vue')
					}, {
						path: '/admin/importData/SportTest',
						name: 'SportTest',
						component: () => import('./views/Admin/ImportPage/SportTest.vue')
					}, {
						path: '/admin/importData/StudentComment',
						name: 'StudentComment',
						component: () => import('./views/Admin/ImportPage/StudentComment.vue')
					}, {
						path: '/admin/importData/BandingInfo',
						name: 'BandingInfo',
						component: () => import('./views/Admin/ImportPage/BandingInfo.vue')
					}, {
						path: '/admin/importData/Calendar',
						name: 'Calendar',
						component: () => import('./views/Admin/ImportPage/Calendar.vue')
					}, ]
				},
				{
					path: '/admin/WeekPercent',
					name: 'WeekPercent',
					component: () => import('./views/Admin/WeekPercent.vue')
				}, {
					path: '/admin/studentScore',
					name: 'studentScore',
					component: () => import('./views/Admin/studentScore.vue')
				}, {
					path: '/admin/BedRoomWeekPoints',
					name: 'BedRoomWeekPoints',
					component: () => import('./views/Admin/BedRoomWeekPoints.vue')
				}, {
					path: '/admin/ClassBedRoomWeekPoints',
					name: 'ClassBedRoomWeekPoints',
					component: () => import('./views/Admin/ClassBedRoomWeekPoints.vue')
				}, {
					path: '/admin/BedroomClassWeeksDetail',
					name: 'BedroomClassWeeksDetail',
					component: () => import('./views/Admin/BedroomClassWeeksDetail.vue')
				}, {
					path: '/admin/ClassViolation',
					name: 'ClassViolation',
					component: () => import('./views/Admin/ClassViolation.vue')
				}, {
					path: '/admin/studentSport',
					name: 'studentSport',
					component: () => import('./views/Admin/studentSport.vue')
				}, {
					path: '/admin/add_SportTest',
					name: 'add_SportTest',
					component: () => import('./views/Admin/add_SportTest.vue')
				}, {
					path: '/admin/Exercises',
					name: 'Exercises',
					component: () => import('./views/Admin/Exercises.vue')
				}, {
					path: '/admin/vacate',
					name: 'vacate',
					component: () => import('./views/Admin/vacate.vue')
				}, {
					path: '/admin/Add_Vacate',
					name: 'Add_Vacate',
					component: () => import('./views/Admin/Add_Vacate.vue')
				}, {
					path: '/admin/procssVacate',
					name: 'procssVacate',
					component: () => import('./views/Admin/procssVacate.vue')
				}, {
					path: '/admin/Punishment_e',
					name: 'Punishment_e',
					component: () => import('./views/Admin/Punishment_e.vue')
				}, {
					path: '/admin/Add_punishment',
					name: 'Add_punishment',
					component: () => import('./views/Admin/Add_punishment.vue')
				}, {
					path: '/admin/punishment_cancel',
					name: 'punishment_cancel',
					component: () => import('./views/Admin/punishment_cancel.vue')
				}, {
					path: '/admin/punishment_delete',
					name: 'punishment_delete',
					component: () => import('./views/Admin/punishment_delete.vue')
				}, {
					path: '/admin/Honor_e',
					name: 'Honor_e',
					component: () => import('./views/Admin/Honor_e.vue')
				}, {
					path: '/admin/Add_Honor',
					name: 'Add_Honor',
					component: () => import('./views/Admin/Add_Honor.vue')
				}, {
					path: '/admin/Integrity1',
					name: 'Integrity1',
					component: () => import('./views/Admin/Integrity1.vue')
				}, {
					path: '/admin/Integrity1_e',
					name: 'Integrity1_e',
					component: () => import('./views/Admin/Integrity1_e.vue')
				}, {
					path: '/admin/Add_integrity',
					name: 'Add_integrity',
					component: () => import('./views/Admin/Add_integrity.vue')
				}, {
					path: '/admin/gooddeed',
					name: 'gooddeed',
					component: () => import('./views/Admin/gooddeed.vue')
				}, {
					path: '/admin/gooddeed_e',
					name: 'gooddeed_e',
					component: () => import('./views/Admin/gooddeed_e.vue')
				}, {
					path: '/admin/Add_SchoolGoodDeed',
					name: 'Add_SchoolGoodDeed',
					component: () => import('./views/Admin/Add_SchoolGoodDeed.vue')
				}, {
					path: '/admin/ClassLeader_e',
					name: 'ClassLeader_e',
					component: () => import('./views/Admin/ClassLeader_e.vue')
				}, {
					path: '/admin/Add_ClassLeader',
					name: 'Add_ClassLeader',
					component: () => import('./views/Admin/Add_ClassLeader.vue')
				}, {
					path: '/admin/Volunteer',
					name: 'Volunteer',
					component: () => import('./views/Admin/Volunteer.vue')
				}, {
					path: '/admin/Volunteer_e',
					name: 'Volunteer_e',
					component: () => import('./views/Admin/Volunteer_e.vue')
				}, {
					path: '/admin/safety',
					name: 'safety',
					component: () => import('./views/Admin/safety.vue')
				}, {
					path: '/admin/Add_safety',
					name: 'Add_safety',
					component: () => import('./views/Admin/Add_safety.vue')
				}, {
					path: '/admin/Sagety_d',
					name: 'Sagety_d',
					component: () => import('./views/Admin/Sagety_d.vue')
				}, {
					path: '/admin/moralEducation',
					name: 'moralEducation',
					component: () => import('./views/Admin/moralEducation.vue')
				}, {
					path: '/admin/moralEducation_e',
					name: 'moralEducation_e',
					component: () => import('./views/Admin/moralEducation_e.vue')
				}, {
					path: '/admin/Add_moralEducation',
					name: 'Add_moralEducation',
					component: () => import('./views/Admin/Add_moralEducation.vue')
				}, {
					path: '/admin/AnomalyStudent',
					name: 'AnomalyStudent',
					component: () => import('./views/Admin/AnomalyStudent.vue')
				}, {
					path: '/admin/Add_AnomalyStudent',
					name: 'Add_AnomalyStudent',
					component: () => import('./views/Admin/Add_AnomalyStudent.vue')
				}, {
					path: '/admin/BackSchool',
					name: 'BackSchool',
					component: () => import('./views/Admin/BackSchool.vue')
				}, {
					path: '/admin/MoralType',
					name: 'MoralType',
					component: () => import('./views/Admin/MoralType.vue')
				}, {
					path: '/admin/studentInfo',
					name: 'studentInfo',
					component: () => import('./views/Admin/studentInfo.vue')
				}, {
					path: '/admin/addOtherViolation',
					name: 'addOtherViolation',
					component: () => import('./views/Admin/addOtherViolation.vue')
				}, {
					path: '/admin/addDutyHygiene',
					name: 'addDutyHygiene',
					component: () => import('./views/Admin/addDutyHygiene.vue')
				}, {
					path: '/admin/addAppearance',
					name: 'addAppearance',
					component: () => import('./views/Admin/addAppearance.vue')
				}, {
					path: '/admin/addLearningAttitude',
					name: 'addLearningAttitude',
					component: () => import('./views/Admin/addLearningAttitude.vue')
				}, {
					path: '/admin/addSportHealth',
					name: 'addSportHealth',
					component: () => import('./views/Admin/addSportHealth.vue')
				}, {
					path: '/admin/addBehaviorHabit',
					name: 'addBehaviorHabit',
					component: () => import('./views/Admin/addBehaviorHabit.vue')
				}, {
					path: '/admin/educateStudent',
					name: 'educateStudent',
					component: () => import('./views/Admin/educateStudent.vue')
				}, {
					path: '/admin/studentInfo2',
					name: 'studentInfo2',
					component: () => import('./views/Admin/studentInfo2.vue')
				}, {
					path: '/admin/addBedRoomViolation',
					name: 'addBedRoomViolation',
					component: () => import('./views/Admin/addBedRoomViolation.vue')
				}, {
					path: '/admin/StudentMessage',
					name: 'StudentMessage',
					component: () => import('./views/Admin/StudentMessage.vue')
				}, {
					path: '/admin/class_WeeksViolation',
					name: 'class_WeeksViolation',
					component: () => import('./views/Admin/class_WeeksViolation.vue')
				}, {
					path: '/admin/editViolation',
					name: 'editViolation',
					component: () => import('./views/Admin/editViolation.vue')
				}, {
					path: '/admin/dispPunishment',
					name: 'dispPunishment',
					component: () => import('./views/Admin/dispPunishment.vue')
				}, {
					path: '/admin/initSchoolStartDate',
					name: 'initSchoolStartDate',
					component: () => import('./views/Admin/initSchoolStartDate.vue')
				}, {
					path: '/admin/editUserPassword',
					name: 'editUserPassword',
					component: () => import('./views/Admin/editUserPassword.vue')
				}, {
					path: '/admin/dispUserList',
					name: 'dispUserList',
					component: () => import('./views/Admin/dispUserList.vue')
				}, {
					path: '/admin/add_insertStudent',
					name: 'add_insertStudent',
					component: () => import('./views/Admin/add_insertStudent.vue')
				}, {
					path: '/admin/add_volunteer',
					name: 'add_volunteer',
					component: () => import('./views/Admin/add_volunteer.vue')
				}, {
					path: '/admin/add_personalPoint',
					name: 'add_personalPoint',
					component: () => import('./views/Admin/add_personalPoint.vue')
				}, {
					path: '/admin/add_classPoint',
					name: 'add_classPoint',
					component: () => import('./views/Admin/add_classPoint.vue')
				}, {
					path: '/admin/Grade1',
					name: 'Grade1',
					component: () => import('./views/Admin/Grade1.vue')
				}, {
					path: '/admin/Grade2',
					name: 'Grade2',
					component: () => import('./views/Admin/Grade2.vue')
				}, {
					path: '/admin/Grade3',
					name: 'Grade3',
					component: () => import('./views/Admin/Grade3.vue')
				}, {
					path: '/admin/studentSearchResult',
					name: 'studentSearchResult',
					component: () => import('./views/Admin/studentSearchResult.vue')
				}, {
					path: '/admin/editStudentSport',
					name: 'editStudentSport',
					component: () => import('./views/Admin/editStudentSport.vue')
				}, {
					path: '/admin/editVacate',
					name: 'editVacate',
					component: () => import('./views/Admin/editVacate.vue')
				}, {
					path: '/admin/default',
					name: 'default',
					component: () => import('./views/Admin/default.vue')
				}, {
					path: '/admin/FileList',
					name: 'FileList',
					component: () => import('./views/Admin/FileList.vue')
				},{
					path: '/admin/StudentList',
					name: 'StudentList',
					component: () => import('./views/Admin/StudentList.vue')
				},{
					path: '/admin/ClassWeeksViolation',
					name: 'ClassWeeksViolation',
					component: () => import('./views/Admin/ClassWeeksViolation.vue')
				},{
					path: '/admin/SettingMessager',
					name: 'SettingMessager',
					component: () => import('./views/Admin/SettingMessager.vue')
				},{
					path: '/admin/BedRoomStudentInfo',
					name: 'BedRoomStudentInfo',
					component: () => import('./views/Admin/BedRoomStudentInfo.vue')
				},{
					path: '/admin/ClassBedRoomInfo',
					name: 'ClassBedRoomInfo',
					component: () => import('./views/Admin/ClassBedRoomInfo.vue')
				},{
					path: '/admin/editBoarderStudentInfo',
					name: 'editBoarderStudentInfo',
					component: () => import('./views/Admin/editBoarderStudentInfo.vue')
				},{
					path: '/admin/editStudentBedInfo',
					name: 'editStudentBedInfo',
					component: () => import('./views/Admin/editStudentBedInfo.vue')
				},{
					path: '/admin/procssClassVacate',
					name: 'procssClassVacate',
					component: () => import('./views/Admin/procssClassVacate.vue')
				}
			]
		}
	]  
})

// 判断是否需要登录权限 以及是否登录 
router.beforeEach((to, from, next) => {
	// 判断是否需要登录权限
	if (to.matched.some(res => res.meta.requireAuth)) {
		// 判断是否登录
		if (sessionStorage.getItem('token')) {
			next()
		} else {
			// 没登录则跳转到登录界面
			next({
				path: '/login',
				query: {
					redirect: to.fullPath
				}
			})
		}
	} else {
		next()
	}
})


// 导航守卫
router.beforeEach((to, from, next) => {

	// 获取 Vue 实例
	const app = router.app;


	if (to.path.startsWith('/admin')) {
		// 这里可以根据实际情况获取用户名
		const username = 'AdminUser';
		app.$isLoggedIn = true;
		app.$username = username;


	} else {
		app.$isLoggedIn = false;
	}
	next();
});


export default router